import React from "react"
import Video from "../components/video"
import {
  Box,
  Button,
  Heading,
} from "@chakra-ui/core"
import Layout from "../components/layout"

const VideoOffer = () => {
  return (
    <Layout>
      <Box
        mx="auto"
        maxWidth={800}
        my={8}
        p={12}
        bg="white"
        rounded="lg"
        textAlign="center"
      >
        <Heading size="2xl" mb={4} fontWeight={700} color="green.500">Launch your MVP</Heading>
        <Heading size="lg" mb={8} fontWeight={400}>Launching your MVP could change your life</Heading>
        <Video videoSrcURL="https://www.youtube.com/embed/uJ_FRHflZAY" />
        <Button
          px={8}
          mt={8}
          variantColor="green"
          type="submit"
          fontWeight="bold"
          py={8}
          fontSize="2xl"
        >
          SIGN UP TODAY
        </Button>        
      </Box>
    </Layout>
  )
}

export default VideoOffer
